var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"elevation":"0"}},[_c('validation-observer',{ref:"observer"},[_c('v-form',{staticClass:"mt-4"},[_c('v-container',{staticClass:"pt-5"},[_c('v-row',[_c('v-col',{staticClass:"d-none d-md-block",attrs:{"md":"2"}}),_c('v-col',{attrs:{"md":"8","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"md":"8","sm":"8"}},[_c('validation-provider',{attrs:{"vid":"descricao"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Descrição","error-messages":errors,"readonly":!_vm.$store.getters['usuario/temPermissao'](
                          'editar.planos'
                        )},model:{value:(_vm.planoNovo.descricao),callback:function ($$v) {_vm.$set(_vm.planoNovo, "descricao", $$v)},expression:"planoNovo.descricao"}})]}}])})],1),_c('v-col',{attrs:{"md":"2","sm":"2"}},[_c('validation-provider',{attrs:{"vid":"ativo"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-switch',{staticClass:"mt-0 pt-0",attrs:{"color":"success","disabled":_vm.$store.state.layout.loading,"label":_vm.planoAtivo,"error-messages":errors,"readonly":!_vm.$store.getters['usuario/temPermissao']()},model:{value:(_vm.planoNovo.ativo),callback:function ($$v) {_vm.$set(_vm.planoNovo, "ativo", $$v)},expression:"planoNovo.ativo"}})]}}])})],1),_c('v-col',{attrs:{"md":"2","sm":"2"}},[_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"primary":"","label":"Padrão"},model:{value:(_vm.planoNovo.default),callback:function ($$v) {_vm.$set(_vm.planoNovo, "default", $$v)},expression:"planoNovo.default"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"8","sm":"12"}},[_c('validation-provider',{attrs:{"vid":"ativo"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-textarea',{attrs:{"auto-grow":"","outlined":"","readonly":!_vm.$store.getters['usuario/temPermissao'](
                          'editar.planos'
                        ),"disabled":_vm.$store.state.layout.loading,"label":"Descrição do plano","error-messages":errors},model:{value:(_vm.planoNovo.complemento),callback:function ($$v) {_vm.$set(_vm.planoNovo, "complemento", $$v)},expression:"planoNovo.complemento"}})]}}])})],1),_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"tickets"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Total de tickets","error-messages":errors,"readonly":!_vm.$store.getters['usuario/temPermissao'](
                              'editar.planos'
                            )},model:{value:(_vm.planoNovo.tickets),callback:function ($$v) {_vm.$set(_vm.planoNovo, "tickets", $$v)},expression:"planoNovo.tickets"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"tickets"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"number","hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Número de área","error-messages":errors,"readonly":!_vm.$store.getters['usuario/temPermissao'](
                              'editar.planos'
                            )},model:{value:(_vm.planoNovo.numero_areas),callback:function ($$v) {_vm.$set(_vm.planoNovo, "numero_areas", $$v)},expression:"planoNovo.numero_areas"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"valor_mensal"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"number","prefix":"R$","hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Valor mensal","error-messages":errors,"readonly":!_vm.$store.getters['usuario/temPermissao'](
                              'editar.planos'
                            )},model:{value:(_vm.planoNovo.valor_mensal),callback:function ($$v) {_vm.$set(_vm.planoNovo, "valor_mensal", $$v)},expression:"planoNovo.valor_mensal"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"valor_anual"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"number","prefix":"R$","hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Valor anual","error-messages":errors,"readonly":!_vm.$store.getters['usuario/temPermissao'](
                              'editar.planos'
                            )},model:{value:(_vm.planoNovo.valor_anual),callback:function ($$v) {_vm.$set(_vm.planoNovo, "valor_anual", $$v)},expression:"planoNovo.valor_anual"}})]}}])})],1)],1)],1)],1)],1),_c('v-col',{staticClass:"d-none d-md-block"})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-card-actions',[_c('v-row',[_c('v-col',{staticClass:"text-left",attrs:{"cols":"12"}},[_c('btn-salvar',{attrs:{"temPermissao":_vm.$store.getters['usuario/temPermissao']('inserir.plano'),"carregando":_vm.carregandoSalvar},on:{"click":function($event){return _vm.$emit('salvar', _vm.planoNovo)}}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }