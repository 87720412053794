<template>
  <div>
    <v-card elevation="0">
      <validation-observer ref="observer">
        <v-form class="mt-4">
          <v-container class="pt-5">
            <v-row>
              <v-col md="2" class="d-none d-md-block" />
              <v-col md="8" sm="12">
                <v-row>
                  <v-col md="8" sm="8">
                    <validation-provider v-slot="{ errors }" vid="descricao">
                      <v-text-field
                        v-model="planoNovo.descricao"
                        outlined
                        dense
                        hide-details="auto"
                        :disabled="$store.state.layout.loading"
                        label="Descrição"
                        :error-messages="errors"
                        :readonly="
                          !$store.getters['usuario/temPermissao'](
                            'editar.planos'
                          )
                        "
                      />
                    </validation-provider>
                  </v-col>

                  <v-col md="2" sm="2">
                    <validation-provider v-slot="{ errors }" vid="ativo">
                      <v-switch
                        v-model="planoNovo.ativo"
                        class="mt-0 pt-0"
                        color="success"
                        :disabled="$store.state.layout.loading"
                        :label="planoAtivo"
                        :error-messages="errors"
                        :readonly="!$store.getters['usuario/temPermissao']()"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col md="2" sm="2">
                    <v-switch
                      v-model="planoNovo.default"
                      primary
                      class="ma-0 pa-0"
                      label="Padrão"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="8" sm="12">
                    <validation-provider v-slot="{ errors }" vid="ativo">
                      <v-textarea
                        auto-grow
                        v-model="planoNovo.complemento"
                        outlined
                        :readonly="
                          !$store.getters['usuario/temPermissao'](
                            'editar.planos'
                          )
                        "
                        :disabled="$store.state.layout.loading"
                        label="Descrição do plano"
                        :error-messages="errors"
                      ></v-textarea>
                    </validation-provider>
                  </v-col>
                  <v-col md="4" sm="12">
                    <v-row>
                      <v-col>
                        <validation-provider v-slot="{ errors }" vid="tickets">
                          <v-text-field
                            v-model="planoNovo.tickets"
                            outlined
                            dense
                            hide-details="auto"
                            :disabled="$store.state.layout.loading"
                            label="Total de tickets"
                            :error-messages="errors"
                            :readonly="
                              !$store.getters['usuario/temPermissao'](
                                'editar.planos'
                              )
                            "
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <validation-provider v-slot="{ errors }" vid="tickets">
                          <v-text-field
                            v-model="planoNovo.numero_areas"
                            outlined
                            dense
                            type="number"
                            hide-details="auto"
                            :disabled="$store.state.layout.loading"
                            label="Número de área"
                            :error-messages="errors"
                            :readonly="
                              !$store.getters['usuario/temPermissao'](
                                'editar.planos'
                              )
                            "
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <validation-provider
                          v-slot="{ errors }"
                          vid="valor_mensal"
                        >
                          <v-text-field
                            v-model="planoNovo.valor_mensal"
                            outlined
                            dense
                            type="number"
                            prefix="R$"
                            hide-details="auto"
                            :disabled="$store.state.layout.loading"
                            label="Valor mensal"
                            :error-messages="errors"
                            :readonly="
                              !$store.getters['usuario/temPermissao'](
                                'editar.planos'
                              )
                            "
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <validation-provider
                          v-slot="{ errors }"
                          vid="valor_anual"
                        >
                          <v-text-field
                            v-model="planoNovo.valor_anual"
                            outlined
                            dense
                            type="number"
                            prefix="R$"
                            hide-details="auto"
                            :disabled="$store.state.layout.loading"
                            label="Valor anual"
                            :error-messages="errors"
                            :readonly="
                              !$store.getters['usuario/temPermissao'](
                                'editar.planos'
                              )
                            "
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="d-none d-md-block" />
            </v-row>
          </v-container>
        </v-form>
      </validation-observer>
      <v-divider class="mt-4" />
      <v-card-actions>
        <v-row>
          <v-col cols="12" class="text-left">
            <btn-salvar
              :temPermissao="
                $store.getters['usuario/temPermissao']('inserir.plano')
              "
              :carregando="carregandoSalvar"
              @click="$emit('salvar', planoNovo)"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>
  
<script>
export default {
  props: {
    validacaoFormulario: {
      type: Object,
      default: () => {
        return {};
      },
    },
    carregandoSalvar: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
    plano: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      planoNovo: {
        id: null,
        descricao: null,
        complemento: null,
        tickets: null,
        valor_mensal: null,
        valor_anual: null,
        ativo: true,
        default: false,
        numero_areas: 0,
      },
    };
  },
  computed: {
    planoAtivo() {
      return this.planoNovo.ativo ? "Ativo" : "Inativo";
    },
  },

  watch: {
    validacaoFormulario(val) {
      if (!val) return;

      return this.$refs.observer.setErrors(val);
    },

    plano(plano) {
      this.planoNovo = plano;
    },
  },
};
</script>
  
